export default class ChartsData {

    constructor() {

        this.pieChartByDonationRecurrence = [];
        this.pieChartByPaymentMethod = [];
        this.pieChartByGender = [];
        this.pieChartByAgeRange = [];
        this.pieChartByProject = [];
        this.pieChartByProjectCause = [];
        this.pieChartByProjectCaptureStatus = [];
        this.pieChartByProjectPhase = [];
        this.pieChartByProjectType = [];
        this.stateData = [];
        this.donationHistory = [];
        
    }

}