<template>
  <div class="mt-2">
    <v-layout ma-0 column elevation-base p-relative>
      <v-flex pa-0>
        <div class="card">
          <div class="card-header big">
            <h2>
              <Icon name="fal fa-history" size="30" class="mr-3" colorFont="#C3C6D5" />Histórico de Doações
            </h2>
          </div>
          <LineChart :value="chartsData.donationHistory" :loading="loading"></LineChart>
        </div>
      </v-flex>
      <div class="loader-circle br-8" v-show="loading">
        <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
      </div>
    </v-layout>
    <v-layout row wrap mt-3>
      <DynamicChart
        title="Gráfico dinâmico"
        icon="fa-chart-line"
        :value="chartsData.donationList"
        :loading="loading"
      />
      <PieChart
        title="Doações x Recorrência"
        icon="fa-clock"
        :value="chartsData.pieChartByDonationRecurrence"
        :loading="loading"
      />
      <PieChart
        title="Forma de Pagamento"
        icon="fa-usd-circle"
        :value="chartsData.pieChartByPaymentMethod"
        :loading="loading"
      />
      <PieChart
        title="Faixa Etária"
        icon="fa-birthday-cake"
        :value="chartsData.pieChartByAgeRange"
        :loading="loading"
      />
      <PieChart
        title="Gênero"
        icon="fa-venus-mars"
        :value="chartsData.pieChartByGender"
        :loading="loading"
      />
    </v-layout>
    <v-layout ma-0 mt-3 column elevation-base p-relative>
      <v-flex pa-0>
        <div class="card">
          <div class="card-header big">
            <h2>
              <Icon name="fal fa-history" size="30" class="mr-3" colorFont="#C3C6D5" />Doações x Estado
            </h2>
          </div>
          <div class="pb-2">
            <StateChart :value="chartsData.stateData" :loading="loading" />
          </div>
        </div>
      </v-flex>
      <div class="loader-circle br-8" v-show="loading">
        <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
      </div>
    </v-layout>
    <v-layout row wrap mt-3>
      <PieChart
        title="Doações x Causa"
        icon="fa-hand-holding-seedling"
        :value="chartsData.pieChartByProjectCause"
        :loading="loading"
      />
      <PieChart
        title="Projetos x Fase"
        icon="fa-thermometer-quarter"
        :value="chartsData.pieChartByProjectPhase"
        :loading="loading"
      />
      <PieChart
        title="Projeto x Status de Captação"
        icon="fa-funnel-dollar"
        :value="chartsData.pieChartByProjectCaptureStatus"
        :loading="loading"
      />
      <PieChart
        title="Doações x Tipo de Projeto"
        icon="fa-tag"
        :value="chartsData.pieChartByProjectType"
        :loading="loading"
      />
      <BarChart
        title="Doações x Projeto (Top 30)"
        icon="fa-file-certificate"
        :value="chartsData.pieChartByProject"
        :loading="loading"
      />
    </v-layout>
  </div>
</template>
<script type="plain/text">
import PieChart from "@/views/app/dashboard/component/PieChart.vue";
import BarChart from "@/views/app/dashboard/component/BarChart.vue";
import LineChart from "@/views/app/dashboard/component/LineChart.vue";
import StateChart from "@/views/app/dashboard/component/StateChart.vue";
import DynamicChart from "@/views/app/dashboard/component/DynamicChart.vue";
import ChartsData from "@/scripts/models/chartsData.model";
export default {
  components: {
    PieChart,
    BarChart,
    LineChart,
    StateChart,
    DynamicChart
  },
  props: {
    chartsData: ChartsData,
    loading: {
      type: Boolean,
      default: true
    }
  }
};
</script>