<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="pa-5">
        <div class="content-dialog-invite">
		      <img src="../assets/images/CreateAccount@2x.png" alt="">
		  <p class="mt-4">Você foi convidado por <span>%username-inviter%</span> da <span>%empresa% </span>para ser um <span>%Perfil%</span> da conta da empresa.</p>
		  <p class="mt-2">Caso você não faça parte da empresa e acredita que este convite tenha sido enviado por engano, <a href="javascript:;">clique aqui</a>.</p>
          <div>
            <button @click="dialog = false;">Aceitar Convite</button>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
export default {
  props: [""],
  data() {
    return {
      dialog: false,
    };
  }
};
</script>