<template>
  <v-layout row wrap class="justify-space-between mb-3 mt-5">
    <v-flex xs12 sm6 shrink style="max-width:300px;">
      <InputDateRange
        v-model="initialFinalDates"
        place-holder="Selecione"
        textLabel="Selecione o período"
        @input="apply()"
      ></InputDateRange>
    </v-flex>
    <v-flex xs12 sm6 text-xs-right>
      <v-dialog v-model="dialog" scrollable max-width="900px">
        <template v-slot:activator="{ on }" v-if="!loading">
          <v-flex shrink>
            <v-btn
              flat
              large
              type="button"
              color="white"
              class="btn-blue ma-0 mt-1 px-4 ml-3"
              v-on="on"
            >
              <Icon name="fas fa-filter" size="16" class="mr-2" colorFont="#fff" />Filtros
            </v-btn>
          </v-flex>
        </template>
        <template v-slot:activator="{ on }" v-else>
          <v-flex shrink>
            <v-btn flat large type="button" color="white" class="btn-primary ma-0 px-4">
              <template>
                <Icon name="far fa-spinner-third fa-spin" size="16" class="mr-3" colorFont="#fff" />Carregando
              </template>
            </v-btn>
          </v-flex>
        </template>
        <v-card>
          <v-card-title class="headline">
            <h1>Filtros</h1>
            <a href="#"></a>
            <v-btn class="close-modal" @click="dialog = false">
              <i class="fal fa-times"></i>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>

          <template>
            <div>
              <v-tabs color="#02ACB4" dark slider-color="white">
                <v-tab ripple>Projetos</v-tab>
                <v-tab ripple>Doações</v-tab>
                <v-tab ripple>Campanhas</v-tab>

                <v-tab-item :key="1">
                  <v-card flat>
                    <v-card-text>
                      <v-container grid-list-lg fluid>
                        <v-layout wrap row list-scroll>
                          <v-flex>
                            <InputMultSelect
                              v-model="selectedProjectPhases"
                              :items="projectPhases"
                              textLabel="Fase do Projeto"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>

                          <v-flex>
                            <InputMultSelect
                              v-model="selectedProjectTypes"
                              :items="projectTypes"
                              textLabel="Tipo do Projeto"
                              valueAttribute="id"
                              textAttribute="name"
                              :showAllOptions="true"
                            />
                          </v-flex>

                          <v-flex>
                            <InputMultSelect
                              v-model="selectedCaptureStatus"
                              :items="captureStatus"
                              textLabel="Status de Captação"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>

                          <v-flex>
                            <InputMultSelect
                              v-model="selectedCauses"
                              :items="causes"
                              textLabel="Causa"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :key="2">
                  <v-card flat>
                    <v-card-text>
                      <v-container grid-list-lg fluid>
                        <v-layout row wrap list-scroll>
                          <v-flex xs6>
                            <InputText
                              v-model="donationFrom"
                              type="number"
                              class="mt-0"
                              textLabel="Valor inicial de doação:"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                            />
                          </v-flex>
                          <v-flex xs6>
                            <InputText
                              v-model="donationTo"
                              type="number"
                              class="mt-0"
                              textLabel="Valor final de doação:"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                            />
                          </v-flex>

                          <v-flex>
                            <InputMultSelect
                              v-model="selectedRecurrences"
                              :items="recurrences"
                              textLabel="Recorrência de Doação"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>

                          <v-flex>
                            <InputMultSelect
                              v-model="selectedGenders"
                              :items="genders"
                              class="mb-0"
                              textLabel="Gênero do Doador"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>
                          <v-flex>
                            <InputMultSelect
                              v-model="selectedPaymentMethods"
                              :items="paymentMethods"
                              textLabel="Forma de Pagamento"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>
                          <v-flex xs8>
                            <InputMultSelect
                              v-model="selectedStates"
                              :items="states"
                              class="mb-0"
                              textLabel="Localização Geográfica do Doador"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <InputMultSelect
                              v-model="selectedEmployeeStatus"
                              :items="employeeStatusList"
                              class="mb-0"
                              textLabel="Funcionário"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :key="3">
                  <v-card flat>
                    <v-card-text>
                      <v-container grid-list-lg fluid>
                        <v-layout row wrap list-scroll>
                          <v-flex>
                            <InputMultSelect
                              v-model="selectedCampaigns"
                              :items="campaigns"
                              textLabel="Campanha"
                              valueAttribute="id"
                              textAttribute="name"
                              :required="false"
                              :showAllOptions="true"
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </div>
          </template>

          <div class="footer-modal-atividades">
			<v-layout wrap>
				<v-flex xs12 sm6 text-xs-center>
					<v-btn
						flat
						round
						large
						color="white"
						class="btn btn-secondary px-4"
						@click="clearFilters();dialog = false"
					>
						<Icon name="far fa-eraser" size="16" class="mr-3" colorFont="#fff" />Limpar Filtros
					</v-btn>
				</v-flex>
				<v-flex xs12 hidden-sm-and-up mt-2></v-flex>
				<v-flex text-xs-center xs12 sm6>
				<v-btn
					flat
					round
					color="white"
					class="btn btn-default px-4 mr-3"
					large
					@click="dialog = false"
				>Cancelar</v-btn>
				<v-btn
					flat
					round
					color="white"
					class="btn btn-primary px-4 ma-0"
					large
					@click="apply();dialog = false"
				>Aplicar</v-btn>
				</v-flex>
			</v-layout>
          </div>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
<script type="plain/text">
import CompanyService from "@/scripts/services/company.service";
export default {
  data() {
    return {
      dialog: false,
      selectedProjects: [],
      selectedCampaigns: [],
      selectedProjectTypes: [],
      selectedCauses: [],
      selectedCaptureStatus: [],
      selectedProjectPhases: [],
      selectedRecurrences: [],
      selectedPaymentMethods: [],
      selectedStates: [],
      selectedGenders: [],
      donationFrom: null,
      donationTo: null,
      initialFinalDates: [],
      selectedEmployeeStatus: [],
      companyService: new CompanyService(),
      filtersLoaded: false,
      filtersFromLocalStorage: []
    };
  },
  props: {
    projects: null,
    campaigns: null,
    projectTypes: null,
    causes: null,
    captureStatus: null,
    projectPhases: null,
    recurrences: null,
    paymentMethods: null,
    states: null,
    genders: null,
    employeeStatusList: null,
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    projects(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedProjects || this.selectedProjects.length == 0)
      )
        this.selectedProjects = this.projects.map(project => project.id);
    },
    campaigns(newValue, oldValue) {
      if (!oldValue && newValue) this.filtersLoaded = true;
      if (
        !oldValue &&
        (!this.selectedCampaigns || this.selectedCampaigns.length == 0)
      ) {
        this.selectedCampaigns = this.campaigns.map(campaign => campaign.id);
      }
    },
    projectTypes(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedProjectTypes || this.selectedProjectTypes.length == 0)
      )
        this.selectedProjectTypes = this.projectTypes.map(
          projectType => projectType.id
        );
    },
    causes(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedCauses || this.selectedCauses.length == 0)
      )
        this.selectedCauses = this.causes.map(cause => cause.id);
    },
    captureStatus(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedCaptureStatus || this.selectedCaptureStatus.length == 0)
      )
        this.selectedCaptureStatus = this.captureStatus.map(cs => cs.id);
    },
    projectPhases(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedProjectPhases || this.selectedProjectPhases.length == 0)
      )
        this.selectedProjectPhases = this.projectPhases.map(
          projectPhase => projectPhase.id
        );
    },
    recurrences(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedRecurrences || this.selectedRecurrences.length == 0)
      )
        this.selectedRecurrences = this.recurrences.map(
          recurrence => recurrence.id
        );
    },
    paymentMethods(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedPaymentMethods ||
          this.selectedPaymentMethods.length == 0)
      )
        this.selectedPaymentMethods = this.paymentMethods.map(
          paymentMethod => paymentMethod.id
        );
    },
    states(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedStates || this.selectedStates.length == 0)
      )
        this.selectedStates = this.states.map(state => state.id);
    },
    genders(newValue, oldValue) {
      if (
        !oldValue &&
        (!this.selectedGenders || this.selectedGenders.length == 0)
      )
        this.selectedGenders = this.genders.map(gender => gender.id);
    }
  },
  created() {
    this.buildSelectedArrays();
  },
  methods: {
    apply() {
      this.$emit("applyFilters", this.getFilters());
    },
    clearFilters() {
      this.companyService.clearDashboardFilters(this.$route.query.entityId);
      this.buildSelectedArrays();
      this.apply();
    },
    getFilters() {
      let selectedFilters = {};
      if (
        !this.filtersFromLocalStorage &&
        (!this.selectedCampaigns || this.selectedCampaigns.length == 0)
      )
        selectedFilters.dashboardFiltersLoaded = false;
      else selectedFilters.dashboardFiltersLoaded = true;
      selectedFilters.entityId = this.$route.query.entityId;
      if (
        this.initialFinalDates &&
        this.initialFinalDates instanceof Array &&
        this.initialFinalDates.length == 2
      ) {
        selectedFilters.initialDate = this.initialFinalDates[0];
        selectedFilters.finalDate = this.initialFinalDates[1];
      } else {
        selectedFilters.initialDate = null;
        selectedFilters.finalDate = null;
      }
      selectedFilters.projects = this.selectedProjects;
      selectedFilters.campaigns = this.selectedCampaigns;
      selectedFilters.causes = this.selectedCauses;
      selectedFilters.captureStatus = this.selectedCaptureStatus;
      selectedFilters.projectPhases = this.selectedProjectPhases;
      selectedFilters.projectTypes = this.selectedProjectTypes;
      selectedFilters.recurrences = this.selectedRecurrences;
      selectedFilters.paymentMethods = this.selectedPaymentMethods;
      selectedFilters.states = this.selectedStates;
      selectedFilters.donationFrom = this.donationFrom;
      selectedFilters.donationTo = this.donationTo;
      selectedFilters.genders = this.selectedGenders;
      selectedFilters.employeeStatus = this.selectedEmployeeStatus;
      if (this.filtersLoaded)
        this.companyService.saveDashboardFiltersToLocalStorage(selectedFilters);
      return selectedFilters;
    },
    buildSelectedArrays() {
      let selectedFilters = this.companyService.loadDashboardFiltersFromLocalStorage(
        this.$route.query.entityId
      );
      this.filtersFromLocalStorage = selectedFilters;
      if (!selectedFilters) {
        this.donationFrom = null;
        this.donationTo = null;
        if (this.projects)
          this.selectedProjects = this.projects.map(project => project.id);
        if (this.campaigns)
          this.selectedCampaigns = this.campaigns.map(campaign => campaign.id);
        if (this.projectTypes)
          this.selectedProjectTypes = this.projectTypes.map(
            projectType => projectType.id
          );
        if (this.causes)
          this.selectedCauses = this.causes.map(cause => cause.id);
        if (this.captureStatus)
          this.selectedCaptureStatus = this.captureStatus.map(cs => cs.id);
        if (this.projectPhases)
          this.selectedProjectPhases = this.projectPhases.map(
            projectPhase => projectPhase.id
          );
        if (this.recurrences)
          this.selectedRecurrences = this.recurrences.map(
            recurrence => recurrence.id
          );
        if (this.paymentMethods)
          this.selectedPaymentMethods = this.paymentMethods.map(
            paymentMethod => paymentMethod.id
          );
        if (this.states)
          this.selectedStates = this.states.map(state => state.id);
        if (this.genders)
          this.selectedGenders = this.genders.map(gender => gender.id);
        if (this.employeeStatusList)
          this.selectedEmployeeStatus = this.employeeStatusList.map(
            employeeStatus => employeeStatus.id
          );
        let firstDate = new Date();
        firstDate.setDate(firstDate.getDate() - 30);
        let lastDate = new Date();
        this.$set(this.initialFinalDates, 0, firstDate);
        this.$set(this.initialFinalDates, 1, lastDate);
      } else {
        this.$set(this.initialFinalDates, 0, selectedFilters.initialDate);
        this.$set(this.initialFinalDates, 1, selectedFilters.finalDate);
        this.selectedProjects = selectedFilters.projects;
        this.selectedCampaigns = selectedFilters.campaigns;
        this.selectedCauses = selectedFilters.causes;
        this.selectedCaptureStatus = selectedFilters.captureStatus;
        this.selectedProjectPhases = selectedFilters.projectPhases;
        this.selectedProjectTypes = selectedFilters.projectTypes;
        this.selectedRecurrences = selectedFilters.recurrences;
        this.selectedPaymentMethods = selectedFilters.paymentMethods;
        this.selectedStates = selectedFilters.states;
        this.donationFrom = selectedFilters.donationFrom;
        this.donationTo = selectedFilters.donationTo;
        this.selectedGenders = selectedFilters.genders;
        this.selectedEmployeeStatus = selectedFilters.employeeStatus;
      }
    }
  }
};
</script>