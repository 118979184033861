<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="pa-5">
        <div class="content-dialog-base">
			<h3 class="w-100">Como deseja atualizar a base de funcionários?</h3>
			<div class="w-100">
				<span class="mt-4 display-flex">Quero enviar um arquivo excel.</span>
				<button type="button" class="btn-primary px-5 ml-0 mt-3 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text">
					<Icon name="fas fa-file-excel mr-2" size="18" colorFont="white" />
					Enviar
				</button>
			</div>
			<borderSeparation></borderSeparation>
			<div class="box-import-file-base">
				<div>Importar da última campanha:</div>
				<div class="display-flex mt-3">
					<div>
						<button type="button" class="btn-primary px-5 ml-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text">
							<Icon name="fas fa-file-import mr-2" size="18" colorFont="white" />
							Importar
						</button>
					</div>
					<div class="name-campaign">
						<div>Nome da campanha</div>
						<span>1254 funcionários</span>
					</div>
				</div>
			</div>
			<div class="btn-cancel mt-4">
				<button @click="dialog = false;">Cancelar</button>
			</div>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
export default {
  props: [""],
  data() {
    return {
      dialog: false,
    };
  }
};
</script>