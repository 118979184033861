<template>
  <div class="p-relative mt-2">
    <v-layout card ma-0 column elevation-base>
      <v-flex pa-0>
        <div>
          <div class="card-header big">
            <h2>
              <Icon name="fal fa-history" size="30" class="mr-3" colorFont="#C3C6D5" />Histórico de Doações
            </h2>
            <v-layout ma-0>
              <v-flex text-xs-right>


                <v-menu offset-y left>
                  <template v-slot:activator="{ on }">
                    <v-btn fab flat class="btn-gray ma-0" v-on="on">
                      <Icon name="fal fa-download" size="16" colorFont="#fff" />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile>
                      <v-list-tile-title @click="downloadExcel()" class="text-dark">
                        <Icon name="fal fa-file-excel mr-0" size="16" colorFont="#c3c6d5" /> Excel
                      </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-title @click="downloadCSV()" class="text-dark">
                        <Icon name="fal fa-file-csv mr-0" size="16" colorFont="#c3c6d5" /> CSV
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>

                <!-- 
					<v-btn fab flat class="btn-gray-dark" @click="downloadExcel()">
					<Icon name="fal fa-download" size="16" colorFont="#fff" />
					</v-btn>
					<v-btn fab flat class="btn-gray-dark" @click="downloadCSV()">
					<Icon name="fal fa-download" size="16" colorFont="#fff" />
					</v-btn> -->



              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
      <v-data-table class="br-4 no-wrap table-traduction mb-2" :headers="headers" :items="analyticalData"
        :pagination.sync="pagination" item-key="id" :rows-per-page-items="[25, 50]"
        no-data-text="Nenhum resultado encontrado">
        <template v-slot:items="props">
          <tr @click="props.expanded = !props.expanded">
            <td>{{ props.item.date | FormatDate("L") }}</td>
            <td class="text-xs-left">{{ props.item.campaignName }}</td>
            <td class="text-xs-left">{{ props.item.companyName }}</td>
            <td class="text-xs-left">{{ props.item.institutionName }}</td>
            <td class="text-xs-left">{{ props.item.projectName }}</td>
            <td class="text-xs-left">{{ props.item.paymentMethod }}</td>
            <td class="text-xs-left">{{ props.item.recurrence }}</td>
            <td class="text-xs-left">R$ {{ formatterHelper.formatMoney(props.item.value) }}</td>
            <td class="text-xs-left">{{ props.item.employeeCompanyName }}</td>
            <td class="text-xs-left">{{ props.item.department }}</td>
            <td class="text-xs-left">{{ props.item.userId }}</td>
            <td class="text-xs-left">{{ props.item.cartId }}</td>
            <td v-for="key in dynamicHeaders" class="text-xs-left">{{ props.item.responses[key] }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
    <div class="loader-circle br-8" v-show="loading">
      <v-progress-circular :value="20" indeterminate color="indigo darken-2"></v-progress-circular>
    </div>
  </div>
</template>
<script type="plain/text">
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import XLSX from "xlsx";
export default {
  props: {
    data: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    formatterHelper: new FormatterHelper(),
    pagination: {
      sortBy: "date"
    },
    dynamicHeaders: [],
    csvHeaders: [
      { text: "Data", value: "date" },
      { text: "Campanha", value: "campaignName" },
      { text: "Empresa", value: "companyName" },
      { text: "Instituição", value: "institutionName" },
      { text: "Projeto", value: "projectName" },
      { text: "Fase", value: "projectPhase" },
      { text: "Tipo", value: "projectType" },
      { text: "Status", value: "projectStatus" },
      { text: "Método de Pagamento", value: "paymentMethod" },
      { text: "Recorrência", value: "recurrence" },
      { text: "Faixa Etária", value: "userAgeRange" },
      { text: "Gênero", value: "userGender" },
      { text: "UF", value: "userState" },
      { text: "Valor", value: "value" },
      { text: "Func. Empresa", value: "employeeCompanyName" },
      { text: "Departamento", value: "department" },
      { text: "Usuário", value: "userId" },
      { text: "Carrinho", value: "cartId" }
    ],
    headers: [
      { text: "Data", value: "date" },
      { text: "Campanha", value: "campaignName" },
      { text: "Empresa", value: "companyName" },
      { text: "Instituição", value: "institutionName" },
      { text: "Projeto", value: "projectName" },
      { text: "Método de Pagamento", value: "paymentMethod" },
      { text: "Recorrência", value: "recurrence" },
      { text: "Valor", value: "value" },
      { text: "Func. Empresa", value: "employeeCompanyName" },
      { text: "Departamento", value: "department" },
      { text: "Usuário", value: "userId" },
      { text: "Carrinho", value: "cartId" }
    ]
  }),
  computed: {
    analyticalData() {
      if (!this.data) return [];
      return this.data;
    }
  },
  watch: {
    data() {
      if (this.data.length === 0) {
        return;
      }
      const dynamicHeaders = [...new Set(this.data.flatMap(item => Object.keys(item.responses)))];
      this.dynamicHeaders = dynamicHeaders;
      const objs = dynamicHeaders.map(header => {
        return { text: header, value: `responses.${header}` };
      });
      this.headers = [...this.headers, ...objs];
      this.csvHeaders = [...this.csvHeaders, ...objs];
    }
  },
  methods: {
    downloadCSV() {
      if (this.analyticalData.length == 0) {
        CommonHelper.swal("", "Nenhum dado disponível para download.", "error");
        return;
      }
      let text = "";
      let i, j;
      for (i = 0; i < this.csvHeaders.length; i++) {
        text += this.csvHeaders[i].text + ";";
      }
      text += "\n";

      for (i = 0; i < this.analyticalData.length; i++) {
        for (j = 0; j < this.csvHeaders.length; j++) {
          let valueToAddToLine = this.analyticalData[i][
            this.csvHeaders[j].value
          ];
          /*if (this.csvHeaders[j].value == "value")
            valueToAddToLine =
              "R$ " + this.formatterHelper.formatMoney(valueToAddToLine);*/
          if (this.csvHeaders[j].value == "date")
            valueToAddToLine = this.formatterHelper.formatDateAndHour(
              valueToAddToLine
            );
          else if (this.csvHeaders[j].value == "isEmployee")
            valueToAddToLine = valueToAddToLine ? "Sim" : "Não";          
          else if (this.csvHeaders[j].value.includes("responses."))
            valueToAddToLine = this.analyticalData[i].responses[this.csvHeaders[j].value.replace("responses.", "")] || "";
               
          text += valueToAddToLine + ";";
        }
        text += "\n";
      }

      var blob = new Blob(["\ufeff" + text], { type: "text/csv" });
      this.save(blob, "csv");
    },
    getWSData() {
      let wsData = [];
      let text = [];
      wsData.push(text);
      let i, j;
      for (i = 0; i < this.csvHeaders.length; i++) {
        text.push(this.csvHeaders[i].text);
      }
      text = [];
      wsData.push(text);

      for (i = 0; i < this.analyticalData.length; i++) {
        for (j = 0; j < this.csvHeaders.length; j++) {
          let valueToAddToLine = this.analyticalData[i][
            this.csvHeaders[j].value
          ];
          /*if (this.csvHeaders[j].value == "value")
            valueToAddToLine =
              "R$ " + this.formatterHelper.formatMoney(valueToAddToLine);*/
          if (this.csvHeaders[j].value == "date")
            valueToAddToLine = this.formatterHelper.formatDateAndHour(
              valueToAddToLine
            );
          else if (this.csvHeaders[j].value == "isEmployee")
            valueToAddToLine = valueToAddToLine ? "Sim" : "Não";
          else if (this.csvHeaders[j].value.includes("responses."))
            valueToAddToLine = this.analyticalData[i].responses[this.csvHeaders[j].value.replace("responses.", "")] || "";
          text.push(valueToAddToLine);
        }
        text = [];
        wsData.push(text);
      }
      return wsData;
    },
    generateExcel() {
      if (this.analyticalData.length == 0) {
        CommonHelper.swal("", "Nenhum dado disponível para download.", "error");
        return;
      }

      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Dados",
        Subject: "Dados",
        Author: "Abrace uma Causa",
        CreatedDate: new Date()
      };
      wb.SheetNames.push("Dados");
      var ws_data = this.getWSData(); //[["hello", "world"]];
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Dados"] = ws;
      return XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    },
    s2ab() {
      let s = this.generateExcel();
      var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      var view = new Uint8Array(buf); //create uint8array as viewer
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    },
    downloadExcel() {
      var blob = new Blob([this.s2ab()], {
        type: "application/octet-stream"
      });
      this.save(blob, "xlsx");
    },
    save(blob, type) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, "Dashboard_AUC.csv");
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "Dashboard_AUC." + type;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }
  }
};
</script>