<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="pa-5">
        <div class="content-dialog-error">
		      <img src="../assets/images/404@2x.png" alt="">
          <span>Pedimos suas desculpas.</span>
          <div>Ocorreu um erro durante a execução</div>
          <p>Caso queira nos informar deste erro <a href="javascript:;">Clique aqui</a> e <br /> comente o ocorrido.</p>
          <div>
            <button @click="dialog = false;">Fechar</button>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
export default {
  props: [""],
  data() {
    return {
      dialog: false,
    };
  }
};
</script>