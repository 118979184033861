<template>
  <v-flex xs12>
    <div class="card">
      <div class="card-header">
        <h2>
          <Icon :name="'fal ' + icon" size="20" class="mr-3" colorFont="#C3C6D5" />
          {{title}}
        </h2>
      </div>
      <div class="px-2 py-3">
        <highcharts :options="options"></highcharts>
      </div>
      <div class="loader-circle br-8" v-show="loading">
        <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
      </div>
    </div>
  </v-flex>
</template>
<script type="plain/text">
export default {
  props: {
    title: String,
    icon: String,
    value: Array,
    loading: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Array,
      default: function() {
        return [
          "#02ACB4",
          "#AFC66F",
          "#297FCA",
          "#710CE7",
          "#80849A",
          "#FA6E6E",
          "#FFB400",
          "#48E5B1"
        ];
      }
    }
  },
  watch: {
    value() {
      if (this) this.setData();
    }
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      if (this && this.options) {
        let valueArray = this.value.slice();
        valueArray.sort(function(a, b) {
          return b.y - a.y;
        });
        valueArray = valueArray.slice(0, 30);
        valueArray.forEach(p => {
          if (p.x.includes("<br>")) return;
          let xWithBreakLines = "";
          let words = p.x.split(" ");
          let count = 0;
          let lineSize = 50;
          for (let i = 0; i < words.length; i++) {
            xWithBreakLines += words[i] + " ";
            count += words[i].length + 1;
            if (count >= lineSize && i < words.length - 1) {
              count = 0;
              xWithBreakLines = xWithBreakLines.slice(0, -1) + "<br>";
            }
          }
          p.x = xWithBreakLines.slice(0, -1);
        });

        let xValues = [];
        let yValues = [];
        let i = 0;
        valueArray.forEach(p => {
          xValues.push(p.x);
          yValues.push({
            y: p.y,
            name: p.x,
            color: this.colors[i % this.colors.length]
          });
          i++;
        });

        this.$set(this.options, "xAxis", {
          categories: xValues,
          title: {
            text: null
          }
        });

        this.$set(this.options, "series", [
          {
            colorByPoint: true,
            showInLegend: false,
            align: "right",
            legendColor: "red",
            name: "Projetos",
            innerSize: "45%",
            data: yValues,
            title: {
              text: null
            },
            dataLabels: {
              distance: -50,
              formatter: function() {
                return Math.round(this.percentage) + "%";
              },
              style: {
                fontWeight: "bold",
                color: "white",
                fontSize: "12px",
                fontFamily: "Nunito",
                textOutline: "0"
              }
            }
          }
        ]);
      }
    }
  },
  data: () => ({
    options: {
      credits: {
        enabled: false
      },
      chart: {
        type: "bar",
        style: {
          fontFamily: "Nunito"
        }
      },
      title: {
        text: null
      },
      tooltip: {
        formatter: function() {
          return (
            "<b>" +
            this.point.name +
            '</b><br><span style="font-size:24px;color:' +
            this.point.color +
            '">\u25CF </span>' +
            this.point.y
          );
        }
      },
      yAxis: {
        title: {
          text: null
        }
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 560
            },
            chartOptions: {
              legend: {
                enabled: false
              }
            }
          }
        ]
      },

      plotOptions: {
        pie: {
          states: {
            inactive: {
              opacity: 0.6
            }
          }
        }
      },
      legend: {
        align: "right",
        margin: 32,
        verticalAlign: "middle",
        layout: "vertical",
        fontFamily: "Nunito",
        itemWrap: false,
        symbolRadius: 6,
        symbolHeight: 20,
        symbolWidth: 20,
        width: "40%",
        itemMarginTop: 8,
        itemMarginBottom: 8,
        itemStyle: {
          color: "#80849A",
          fontWeight: "600",
          top: "8px",
          transform: "translate(0, -6px)"
        },
        labelFormatter: function() {
          return this.x;
        }
      }
    }
  })
};
</script>