<template>
  <v-flex xs12>
    <div class="card">
      <div class="card-header">
        <h2>
          <Icon :name="'fal ' + icon" size="20" class="mr-3" colorFont="#C3C6D5" />
          {{ title }}
        </h2>
      </div>
      <div class="px-2 py-3">
        <div id="output" style="max-width: 500px;"></div>
      </div>
      <div class="loader-circle br-8" v-show="loading">
        <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
      </div>
    </div>
  </v-flex>
</template>
<script type="plain/text">
export default {
  props: {
    title: String,
    icon: String,
    value: Array,
    loading: {
      type: Boolean,
      default: true,
    },
  },
  Data() {
    return {
      localLoading: false,
    };
  },
  watch: {
    value() {
      if (this) this.initPivot();
    },
  },
  async mounted() {
    var scripts = [
      "https://cdn.plot.ly/plotly-basic-latest.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/1.11.2/jquery.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/jqueryui-touch-punch/0.2.3/jquery.ui.touch-punch.min.js",      
      "/pivottable/js/pivot.js",      
      "/pivottable/js/plotly_renderers.js",
      "/pivottable/js/pivot.pt.js",
    ];
    var css = [
      "/pivottable/css/pivot.css",
    ];

    // Função para carregar um script e retornar uma Promise
    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    }

    // Função para carregar um CSS
    function loadCSS(href) {
      return new Promise((resolve, reject) => {
        const link = document.createElement("link");
        link.href = href;
        link.type = "text/css";
        link.rel = "stylesheet";
        link.onload = resolve;
        link.onerror = reject;
        document.head.appendChild(link);
      });
    }
    // load scripts await loading
    for (let i = 0; i < scripts.length; i++) {
      await loadScript(scripts[i]);
    }
    // load css await loading
    for (let i = 0; i < css.length; i++) {
      await loadCSS(css[i]);
    }
    // evit value empty
    setTimeout(() => {
      this.initPivot();
    }, 3000);
  },
  methods: {
    initPivot() {
      var renderers = $.extend($.pivotUtilities.renderers,
        $.pivotUtilities.plotly_renderers);

      const mps = this.value;

      $("#output").pivotUI(function (injectRecord) {
        mps.map(function (mp) {
          injectRecord({
            "Valor da doação": mp.value,
            "Data do pagamento": mp.date,
            "Mês": mp.month,
            "Ano": mp.year,
            "Projeto": mp.projectName,
            "Campanha": mp.campaignName,
            "Empresa": mp.companyName,
            "Instituição": mp.institutionName,
            "Empresa Func.": mp.employeeCompanyName,
            "Departamento": mp.department,
            "Método de Pagamento": mp.paymentMethod,
            "Recorrência": mp.recurrence,
            "Range de Idade": mp.userAgeRange,
            "Gênero": mp.userGender,
            "Cidade": mp.userCity,
            "Estado": mp.userState,
            "Tipo Projeto": mp.projectType,
            "Status Projeto": mp.projectStatus,
            "Id carrinho": mp.cartId,
            "Produto de doação": mp.donationProduct,
            "Status do repasse": mp.cashFlowStep,
            "Valor Líquido": mp.liquidValue,
            ...mp.responses
          });
        });
      }, {
        renderers: renderers,
        cols: ["Método Pagamento"], 
        rows: ["Estado"],
        rendererName: "Horizontal Bar Chart",
        aggregatorName: "Soma",
        vals: ["Valor da doação"],
        rowOrder: "value_a_to_z", colOrder: "value_z_to_a",
        localeStrings: "pt",
        width: 800,
      },
        false,
        "pt"
      );
    }
  }
};
</script>