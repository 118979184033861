<template>
  <v-flex pt-5 pb-4>
    <highcharts :options="options"></highcharts>
  </v-flex>
</template>
<script type="plain/text">
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  props: {
    value: Array,
    loading: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Array,
      default: function() {
        return [
          "#02ACB4",
          "#AFC66F",
          "#297FCA",
          "#710CE7",
          "#80849A",
          "#FA6E6E",
          "#FFB400",
          "#48E5B1"
        ];
      }
    }
  },
  data() {
    return {
      formatterHelper: FormatterHelper
    };
  },
  watch: {
    value() {
      if (this) this.setData();
    }
  },
  created() {
    this.formatterHelper = new FormatterHelper();
    this.setData();
  },
  methods: {
    setData() {
      if (this && this.options && this.value) {
        let i = 0;
        let series = this.value.map(serie => {
          let newSerie = {};
          if (serie.seriesName == "Valor") newSerie.yAxis = 1;
          newSerie.color = this.colors[i % this.colors.length];
          i++;
          newSerie.name = serie.seriesName;
          newSerie.data = serie.points.map(point => {
            let date = this.formatterHelper.parseDate(point.x, "YYYY-MM-DD");
            return [
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
              point.y
            ];
          });
          // if (serie.seriesName == "Valor")
          //   newSerie.tooltip = {
          //     headerFormat: "<b>Valor total doado</b><br>",
          //     pointFormat: "R$ {point.y:.2f}"
          //   };
          // else
          //   newSerie.tooltip = {
          //     headerFormat: "<b>Quantidade de doações</b><br>",
          //     pointFormat: "{point.y}"
          //   };
          return newSerie;
        });
        this.$set(this.options, "series", series);
      }
    }
  },
  data: () => ({
    options: {
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%d/%m/%y"
        }
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: "{value}"
          },
          title: {
            text: "" //Quantidade de doações
          }
        },
        {
          // Secondary yAxis
          title: {
            text: "" //Valor total doado
          },
          labels: {
            format: "R${value}"
          },
          opposite: true
        }
      ],
      // tooltip: {
      //   headerFormat: "<b>{series.name}</b><br>"
      //   //pointFormat: "R$ {point.y:.2f}"
      // },
      tooltip: {
        formatter: function() {
          if (this.series.name == "Valor")
            return (
              '<b>Valor total doado</b><br><br/><span style="color:' +
              this.point.color +
              '">\u25CF</span>R$ ' +
              new FormatterHelper().formatMoney(this.point.y)
            );
          else
            return (
              '<b>Quantidade de doações</b><br><span style="color:' +
              this.point.color +
              '">\u25CF</span>' +
              this.point.y
            );
        }
      },
      chart: {
        type: "areaspline"
      }
    }
  })
};
</script>