import BaseCrudService from "./baseCrud.service";

export default class ReportService extends BaseCrudService {

    constructor() {
        super('Report');
    }

    drawReport(campaignName){
        return this.getRequestBlob("ReportDraw", {campaignName: campaignName});
    }

    internalReportDownload(campaignName){
        return this.getRequestBlob("InternalReportDownload", {campaignName: campaignName});
    }

    geralReportByEntity(entityId, year, subdomain){
        return this.getRequestBlob("GeralReport", {entityId: entityId, year: year, subdomain: subdomain});
    }

    cashFlowReport(cashFlowFilters){
        return this.postRequestBlob("CashFlowReport", {statusConciliation: cashFlowFilters.statusConciliation, statusTransferResource: cashFlowFilters.statusTransferResource, paymentMethod: cashFlowFilters.paymentMethod, entityId: cashFlowFilters.entityId });
    }

    iasReport(){
        return this.getRequestBlob("IASReport"); 
    }

    volunteeringReport(campaignName) {
        return this.getRequestBlob("VolunteeringReport", { campaignName: campaignName });
    }

    manualSignatureReport(Receipts) {
        return this.postRequestBlob("ManualSignatureReport", Receipts);
    }
}