<template>
  <v-layout row wrap class="map-states">
    <v-flex xs12 md6>
      <highcharts constructor-type="mapChart" :options="brazilMap" class="map"></highcharts>
    </v-flex>
    <v-flex xs12 md6>
      <highcharts :options="brazilBar"></highcharts>
    </v-flex>
  </v-layout>
</template>
<script type="plain/text">
import Highcharts from "highcharts";
import BrazilMapData from "@/scripts/configs/brazil.geo.js";
export default {
  componets: {
    Highcharts
  },
  props: {
    value: Array,
    loading: {
      type: Boolean,
      default: true
    }
  },
  created() {
    Highcharts.maps["countries/br/br-all"] = BrazilMapData;
    this.buildData();
  },
  watch: {
    value() {
      if (this) this.buildData();
    }
  },
  methods: {
    buildData() {
      this.orderDataByValue();
      this.buildBarCategories();
      this.buildBarData();
      this.buildMapData();
    },
    orderDataByValue() {
      this.orderedStateData = this.value.slice().sort((a, b) => b.y - a.y);
    },
    buildBarCategories() {
      this.brazilBar.xAxis.categories = this.orderedStateData.map(a => a.x);
    },
    buildBarData() {
      let values = this.orderedStateData.map(a => a.y);
      this.barMaxValue = Math.max(...values);
      this.$set(this.brazilBar, "series", [
        {
          data: values.map(v => this.barMaxValue - v),
          color: "#F1F2F5",
          borderRadius: 3,
          pointWidth: 6,
          dataLabels: {
            enabled: true,
            align: "center",
            inside: false,
            formatter: function() {
              return this.series.dataMax - this.point.y;
            },
            color: "#02ACB4",
            x: 20
          },
          enableMouseTracking: false
        },
        {
          color: "#02ACB4",
          borderRadius: 3,
          pointWidth: 6,
          data: values,
          dataLabels: {
            enabled: false
          }
        }
      ]); /*[0].data = values.map(v => this.barMaxValue - v);
      this.brazilBar.series[1].data = values;*/
    },
    buildMapData() {
      this.$set(this.brazilMap, "series", [
        {
          data: this.value.map(a => [a.x, a.y]),
          keys: ["postal-code", "value"],
          joinBy: "postal-code",
          states: {
            hover: {
              color: "#02ACB4"
            }
          },
          dataLabels: {
            enabled: false
          }
        }
      ]);
    }
  },
  data: () => ({
    barMaxValue: 0,
    orderedStateData: [],
    brazilBar: {
      chart: {
        type: "bar",
        height: 650
      },
      title: {
        text: null
      },
      xAxis: {
        title: {
          text: null
        },
        lineWidth: 0,
        labels: {
          align: "center"
        }
      },
      yAxis: {
        visible: false
      },
      tooltip: {
        formatter() {
          return this.point.category + "<br>" + this.point.y;
        },
        style: {
          fontWeight: "bold"
        }
      },
      plotOptions: {
        series: {
          stacking: "normal"
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    },
    brazilMap: {
      chart: {
        map: "countries/br/br-all",
        type: "map",
        height: 650
      },
      title: {
        text: null
      },
      mapNavigation: {
        enabled: false
      },
      colorAxis: {
        min: 0,
        stops: [[0, "#F3F3F5"], [1, "#80849A"]]
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "{point.properties.postal-code}<br>{point.value}",
        style: {
          fontWeight: "bold"
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    }
  })
};
</script>