export default class DashboardData {

    constructor() {

        this.donatedTotalValue = 0;
        this.uniquelyDonatedTotalValue = 0;
        this.recurrentlyDonatedTotalValue = 0;
        this.capturedTotalDonationValue = 0;
        this.totalDonors = 0;
        this.uniquelyDonors = 0;
        this.recurrentlyDonors = 0;
        this.campaignsQty = 0;
        this.projectsQty = 0;
        this.campaigns = [];
        this.totalDonationByRecurrence = [];
        this.totalDonationByPaymentMethod = [];
        this.totalDonationByGender = [];
        this.totalDonationByAgeRange = [];
        this.totalDonationByProject = [];
        this.totalDonationByProjectCause = [];
        this.totalDonationByProjectCaptureStatus = [];
        this.totalDonationByProjectPhase = [];
        this.totalDonationByProjectType = [];
        this.totalDonationByState = [];
        this.donationHistory = [];
        this.donationList = [];
        this.dashboardFiltersViewModel = [];
        
    }

}